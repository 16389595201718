import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import RegisterPage from './components/Register';
import Page from './components/Shared/Page';
import TestimonialsPage from './components/TestimonialsPage/TestimonialsPage';

function App() {

  return (
    <>

      {/* <ImageCache /> */}

      <Routes>
        <Route path="/" element={<Page />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="testimonials" element={<TestimonialsPage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;