import MarySummary from "./MarySummary";
import MaryExperience from "./MaryExperience";
import Values from "./Values";
import MaryPets from "./MaryPets";
import heroImage from '../../assets/HeroBanner/herobanner-03.jpg';
import { AppContext } from "../../AppContextProvider";
import { useContext, useEffect } from "react";
import Divider from "../Shared/Divider";
import PawButton from "../Shared/PawButton";
import { Link } from "react-router-dom";
import { RandomTestimonial } from "../Shared/Testimonial";

function AboutPage() {

    const { setHeroBannerConfig } = useContext(AppContext);
    useEffect(() => setHeroBannerConfig({ title: 'About Mary', heroImage }));

    return (
        <>
            <MarySummary />
            <Divider />
            <MaryExperience />
            {/* <Divider /> */}
            <Buttons />
            <Divider />
            <Values />
            {/* <MaryPets /> */}
            <RandomTestimonial />
        </>
    );
}

function Buttons() {
    return (
        <div className="flex flex-col md:flex-row justify-center items-center mt-4 mb-4 gap-4">
            <PawButton variant="secondary" component={Link} to="/services" text="Our services" />
            <PawButton component={Link} to="/register" text="Contact us" />
        </div>
    )
}

export default AboutPage;