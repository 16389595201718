import Intro from './Intro'
import Divider from '../Shared/Divider';
import heroImage from '../../assets/HeroBanner/herobanner-01.jpg';
import ImportantGoals from './ImportantGoals';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContextProvider';
import { RandomTestimonial } from '../Shared/Testimonial';

function HomePage() {

    const { setHeroBannerConfig } = useContext(AppContext);

    useEffect(() => {
        setHeroBannerConfig({
            title: 'Mary Austin',
            subtitle: 'New Zealand Cat Behaviour Detective',
            heroImage
        });
    });

    return (
        <>
            <Intro />
            <Divider />
            <ImportantGoals />
            <RandomTestimonial />
        </>
    );
}

export default HomePage;