import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContextProvider';
import Navbar from '../Navbar';
import defaultHeroImage from '../../../assets/HeroBanner/herobanner-01.jpg';
import styles from './HeroBanner.module.css';

export default function HeroBanner() {

    const { title, subtitle, heroImage } = useContext(AppContext);
    const [activeDivIndex, setActiveDivIndex] = useState(0);
    const [imageNames, setImageNames] = useState([defaultHeroImage, defaultHeroImage]);
    const [animationClassNames, setAnimationClassNames] = useState([undefined, undefined]);
    const divRefs = [useRef(), useRef()];

    /**
     * Whenever the hero image changes, run this, which will trigger the crossfade animation
     * if the image has actually changed.
     */
    useEffect(() => {

        // If the new hero image is undefined OR the same as the old one, get outta here.
        if (heroImage === undefined) return;
        const activeDiv = divRefs[activeDivIndex].current;
        if (!activeDiv) return;
        const cbi = activeDiv.style.backgroundImage;
        if (cbi.substring(5).startsWith(heroImage)) return;

        changeImage(heroImage);

    }, [heroImage]);

    /**
     * Swaps the image to the given one, by setting the inactive image first, then beginning
     * the crossfade animation.
     */
    const changeImage = (image) => {
        // console.log(`Need to change hero image to ${image}`);

        // Make the inactive div fade in and the active one fade out
        const newAnimationNames = [];
        newAnimationNames[activeDivIndex] = styles.fadingOut;
        newAnimationNames[1 - activeDivIndex] = styles.fadingIn;
        setAnimationClassNames(newAnimationNames);

        // Make the inactive div contain the new image
        const newImageNames = [...imageNames];
        newImageNames[1 - activeDivIndex] = image;
        setImageNames(newImageNames);

        // Swap active and inactive
        setActiveDivIndex(1 - activeDivIndex);
    }

    return (
        <div className={`w-full h-[400px] md:h-[500px] lg:h-[550px] relative`}>

            <div ref={divRefs[0]} className={`w-full h-full absolute z-0 bg-no-repeat bg-cover bg-center ${animationClassNames[0]}`}
                style={{ backgroundImage: `url(${imageNames[0]})` }} />

            <div ref={divRefs[1]} className={`w-full h-full absolute z-0 bg-no-repeat bg-cover bg-center ${animationClassNames[1]}`}
                style={{ backgroundImage: `url(${imageNames[1]})` }} />

            <div className='w-full h-full absolute z-10 grid grid-cols-1 grid-rows-[auto_1fr]'>
                <Navbar />

                {title && <header className="text-white self-end pt-4 pb-4 text-5xl md:text-7xl text-center bg-black bg-opacity-50">
                    {title}
                </header>}

                {subtitle && <header className="text-gray-100 self-end pt-4 pb-4 text-xl md:text-3xl font-light text-center bg-black bg-opacity-50">
                    {subtitle}
                </header>}
            </div>
        </div>
    )
};