import React from 'react';
import Paragraph from '../Shared/Paragraph';
import imgExperience from '../../assets/AboutPage/Values/experience.jpg';
import imgConfidential from '../../assets/AboutPage/Values/confidential.jpg';
import imgCaring from '../../assets/AboutPage/Values/caring.jpg';
import imgUnderstanding from '../../assets/AboutPage/Values/understanding.jpg';

const Values = () => {
    // flex flex-row items-center justify-around flex-wrap gap-4
    return (
        <div className="grid grid-cols-2 md:grid-cols-4 mt-2 mb-2">
            <Point image={imgExperience} alt="A cat solving a puzzle" text="20+ years' experience" />
            <Point image={imgCaring} alt="Mary working with a cat in the consult room" text="Caring" />
            <Point image={imgUnderstanding} alt="Mary's sister with her beloved ginger cat" text="Understanding" />
            <Point image={imgConfidential} alt="A cat on a trip to the vet" text="Confidential" />
        </div>
    )
}

function Point({image, alt, text}) {
    return (
        <div className="flex flex-col items-center justify-start">
            <img src={image} alt={alt} className="rounded-full border-blue-900 border-4 object-cover w-40 h-40" />
            <Paragraph variant="primary" className="text-center">{text}</Paragraph>
        </div>
    )
}

export default Values;