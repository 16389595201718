import ServicesIntro from "./ServicesIntro";
import heroImage from '../../assets/HeroBanner/herobanner-02.jpg';
import Divider from '../Shared/Divider';
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContextProvider";
import ServicesDetail from "./ServicesDetail";
import { Link } from "react-router-dom";
import PawButton from "../Shared/PawButton";
import { RandomTestimonial } from "../Shared/Testimonial";

function ServicesPage() {

    const { setHeroBannerConfig } = useContext(AppContext);
    useEffect(() => setHeroBannerConfig({ title: 'Services', heroImage }));

    return (
        <>
            <ServicesIntro />
            <Divider />
            <ServicesDetail />
            <Buttons />
            <RandomTestimonial />
        </>
    );
}

function Buttons() {
    return (
        <div className="flex flex-col md:flex-row justify-center items-center mt-4 mb-4 gap-4">
            <PawButton variant="secondary" component={Link} to="/about" text="About Mary" />
            <PawButton component={Link} to="/register" text="Contact us" />
        </div>
    )
}

export default ServicesPage;