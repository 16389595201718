import img from "../../assets/mary-smiling-cat.jpg";
import ImageAndContent from "../Shared/ImageAndContent";
import Heading from "../Shared/Heading";
import Paragraph from "../Shared/Paragraph";
import { Link } from "react-router-dom";
import PawButton from "../Shared/PawButton";

const MarySummary = () => {
    return (
        <ImageAndContent imgSrc={img} imgAlt="Mary holding a cat">
            <Heading l={2}>Mary Austin</Heading>
            <Paragraph variant="primary">
                Mary has had an enduring understanding, obsession with, and love of cats, spanning most of her life.
                Holding several prestigious qualifications and having two decades' experience in feline-only
                veterinary care, she is the purrfect person to understand and manage your cat's
                behaviour problems.
            </Paragraph>

            <div className="flex flex-row gap-6 flex-wrap justify-center">
                <PawButton variant="secondary" component={Link} to="/services" text="Our services" />
                <PawButton component={Link} to="/register" text="Contact us" />
            </div>
        </ImageAndContent>
    )
};

export default MarySummary;