import React from "react";
import primarySvg from '../../assets/SVG/paw-primary.svg';
import secondarySvg from '../../assets/SVG/paw-secondary.svg';

const variants = {
    primary: {
        bgColorClass: "bg-red-500 group-hover:bg-red-300",
        textColorClass: "text-white",
        imgSrc: primarySvg
    },
    secondary: {
        bgColorClass: "bg-blue-400 group-hover:bg-blue-200",
        textColorClass: "text-white",
        imgSrc: secondarySvg
    }
}

export default function PawButton({ variant, text, ...rest }) {

    const v = variants[variant] ? variants[variant] : variants.primary;

    return (
        <Internal className={`group relative flex items-center
            cursor-pointer font-semibold`} {...rest}>
            <img alt="cat paw" className="absolute w-16 h-16 -top-2 left-0
                transition-all duration-200 group-hover:scale-105 group-hover:rotate-6" src={v.imgSrc} />

            <div className={`pl-16 pr-4 py-2 ml-2 group-hover:ml-3 my-1 ${v.bgColorClass} ${v.textColorClass} rounded-full
                transition-all duration-200 text-sm md:text-base`}>{text}</div>
        </Internal>
    );
}

function Internal({ component, children, ...rest }) {
    if (component) {
        return React.createElement(component, rest, children);
    }

    return <div {...rest}>{children}</div>
}