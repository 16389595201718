import Heading from "../Shared/Heading";
import Paragraph from "../Shared/Paragraph";

export default function TermsAndConditions() {
    return (
        <div className="max-w-3xl m-auto px-2 md:px-0 mb-2">
            <Heading l={3}>Terms &amp; conditions</Heading>
            <Paragraph variant="tertiary" className="mb-2">
                The cost is $150 per hour. Fees are payable prior to phone or Zoom consultations. Payment is via internet banking.
            </Paragraph>
        </div>
    )
}