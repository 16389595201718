import { NavLink } from "react-router-dom";
import { MenuIcon } from '@heroicons/react/outline';
import { useState } from "react";

export default function Navbar() {
    const [isNavToggled, setIsNavToggled] = useState(false);

    function toggleNav() {
        setIsNavToggled(!isNavToggled);
    }

    const menuLeft = isNavToggled ? "left-0" : "left-[-100%]";

    return (
        <nav className={`m-0 p-0 list-none overflow-hidden text-white bg-black bg-opacity-50
            md:flex md:flex-row md:justify-between`}>

            <MenuIcon onClick={toggleNav} className="cursor-pointer p-2 w-12 h-12 md:invisible" />

            <ul className={`flex flex-col p-2 absolute top-12 ${menuLeft} transition-all gap-4 bg-black bg-opacity-50 w-full
                md:static md:top-auto md:left-auto md:w-auto md:flex-row md:bg-transparent md:p-0 md:px-4`}>

                <Link onClick={() => setIsNavToggled(false)} to="/" text="Home" />

                <Link onClick={() => setIsNavToggled(false)} to="/services" text="Services" />

                <Link onClick={() => setIsNavToggled(false)} to="/about" text="About Mary" />

                <Link onClick={() => setIsNavToggled(false)} to="/testimonials" text="Testimonials" />

                <Link onClick={() => setIsNavToggled(false)} to="/register" text="Register Interest" />

            </ul>
        </nav>
    );
}

function Link({ to, text, onClick }) {
    return (
        <li className="m-0 p-0 flex flex-col md:flex-row">
            <NavLink onClick={onClick} to={to} className={({ isActive }) =>
                `${isActive ? 'bg-white bg-opacity-30' : 'bg-inherit'} hover:bg-white hover:bg-opacity-30 transition-colors
                    font-semibold flex items-center p-2`}>{text}</NavLink>
        </li>
    );
}