import Heading from '../Shared/Heading';
import Timeline from '../Shared/Timeline';
import Paragraph from '../Shared/Paragraph';
import img from '../../assets/HomePage/cat-on-chair.jpg';
import ImageAndContent from '../Shared/ImageAndContent';

export default function ImportantGoals() {

    return (
        <ImageAndContent imgSrc={img} imgAlt="Cat on chair" imgRight={true}>
            
            <Heading l={2} className="mb-4 self-start">My goals when observing your cat</Heading>

            <Paragraph variant="primary">
                When observing your cat's behaviour, I aim to:
            </Paragraph>

            <Timeline fit={true}>
                <Paragraph variant="primary"><strong>Understand</strong> your cat's behaviour</Paragraph>
                <Paragraph variant="primary">Understand how to <strong>respond</strong> to your cat's behaviour</Paragraph>
                <Paragraph variant="primary">Understand behavioural aspects that may be <strong>changed</strong></Paragraph>
                <Paragraph variant="primary">Maximize the bond of <strong>love</strong> between you and your cat.</Paragraph>
            </Timeline>

            <Paragraph variant="primary"><em>Understanding your cat's behaviour is the power that maximizes the bond of love.</em></Paragraph>
        </ImageAndContent>
    )
}