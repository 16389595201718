import heroImage from '../../assets/HeroBanner/herobanner-01.jpg';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContextProvider';
import useGet from '../../hooks/useGet';
import Paragraph from '../Shared/Paragraph';
import Testimonial from '../Shared/Testimonial';

export default function TestimonialsPage() {

    const { setHeroBannerConfig } = useContext(AppContext);
    useEffect(() => setHeroBannerConfig({ title: 'Testimonials', heroImage }));

    const { data, isLoading } = useGet(`${process.env.REACT_APP_API_BASE_URL}/testimonials`);

    if (isLoading || !data) return (
        <Paragraph variant="primary" className="text-center">🐾 🐾 Loading... 🐾 🐾</Paragraph>
    )

    return (
        <div className="flex flex-col gap-4">
            {data.map((t, i) => <Testimonial key={i} {...t} />)}
        </div>
    )
}