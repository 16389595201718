import RegisterForm from "./RegisterForm";
import heroImage from '../../assets/HeroBanner/herobanner-04.jpg';
import { useContext, useEffect } from "react";
import { AppContext } from "../../AppContextProvider";
import RegisterIntro from "./RegisterIntro";
import TermsAndConditions from "./TermsAndConditions";
import Divider from '../Shared/Divider';

function RegisterInterestPage() {

    const { setHeroBannerConfig } = useContext(AppContext);
    useEffect(() => setHeroBannerConfig({ title: 'Register Interest', heroImage }));

    return (
        <>
            <RegisterIntro />
            <Divider />
            <RegisterForm />
            <Divider />
            <TermsAndConditions />
        </>
    );
}

export default RegisterInterestPage;