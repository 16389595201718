export default function CircularImage({ src, className, ...rest }) {

    let clazz = "object-cover rounded-full";
    if (className) {
        clazz += " " + className;
    }

    return (
        <img src={src} className={clazz} {...rest} />
    )
}