import React from 'react';
import img from "../../assets/Register/register-interest-cat.jpg";
import ImageAndContent from '../Shared/ImageAndContent/ImageAndContent';
import Heading from '../Shared/Heading';
import Paragraph from '../Shared/Paragraph';

export default function RegisterIntro() {

    return (
        <ImageAndContent imgSrc={img} imgAlt="Mary with a cat">

            <Heading l={2}>Contact us</Heading>

            <Paragraph variant="primary">
                If you'd like to enquire further, or to book a consultation, please use the form below. Mary will
                be in touch within 10 days.
            </Paragraph>

        </ImageAndContent>
    )
};