import Heading from "../Shared/Heading"
import Timeline from "../Shared/Timeline"
import Paragraph from "../Shared/Paragraph";

export default () => {
    return (
        <div className="flex flex-col items-center">
            <Heading l={3} className="mb-2">Mary has the pleasure of offering you the following services:</Heading>
            
            <Timeline fit={false}>

                <Paragraph variant="secondary">Zoom and / or phone consultations to discuss your cat's behaviour</Paragraph>

                <Paragraph variant="secondary">Evaluation of video recordings of your cat's behaviour</Paragraph>

                <Paragraph variant="secondary">Evaluation of special house maps or plans relating to your cat's behaviour</Paragraph>

                <Paragraph variant="secondary">Understanding your cat's past history. What has worked? What has not worked? Has anything worsened the problem?</Paragraph>

                <Paragraph variant="secondary">Exploration of the first eight weeks of your cat's life. This information is often unknown but ongoing concerns may give Mary an idea on what the first eight weeks were like.</Paragraph>

                <Paragraph variant="secondary">Recommendations on changes that can be made</Paragraph>

                <Paragraph variant="secondary">Short and long-term goal setting</Paragraph>

                <Paragraph variant="secondary">Referral to a primary care vet, if required for medication or blood tests <em>(only with your agreement)</em></Paragraph>

                <Paragraph variant="secondary">Keeping your primary care vet informed on your cat's behavioural assessment and treatment response <em>(only with your permission)</em>.</Paragraph>

            </Timeline>
        </div>
    )
}
