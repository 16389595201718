export default function Paragraph({ variant, className, children, ...rest }) {

    const variantClasses = useVariantClassNames(variant);
    const clazz = className ? `${variantClasses} ${className}` : variantClasses;

    return (
        <p className={clazz} {...rest}>{children}</p>
    )
}

function useVariantClassNames(variant) {
    switch (variant) {
        case "primary":
            return "text-xl md:leading-loose lg:leading-normal xl:leading-loose 2xl:leading-[3.5]"

        case "secondary":
            return "font-light text-lg leading-normal md:leading-loose";

        case "tertiary":
            return "font-light text-sm leading-normal text-gray-400";

        default:
            return "";
    }
}