import React from "react"
import paw from "../../assets/paw-print.png";

export default function Timeline({ children, fit, className }) {

    const maxW = fit ? "max-w-fit" : "max-w-fit md:max-w-4xl";
    let clazz = `grid grid-cols-[50px_1fr] gap-6 ${maxW} px-4 items-center`;
    if (className) clazz += ` ${className}`;

    return (
        <div className={clazz}>
            {children && React.Children.map(children, (child, i) => (
                <React.Fragment key={i}>
                    <Decoration />
                    {child}
                </React.Fragment>
            ))}
        </div>
    )

}

function Decoration() {
    return (
        <div className="flex flex-col justify-start items-center">
            <img src={paw} alt="" />
        </div>
    )
}

// function Square() {
//     return (
//         <div className="w-6 h-6 rotate-45 bg-slate-500" />
//     )
// }

// function Line() {
//     return (
//         <div className="bg-slate-500 w-[2px] h-48" />
//     )
// }
