import Heading from "../Shared/Heading"
import Timeline from "../Shared/Timeline"
import Paragraph from "../Shared/Paragraph";

export default () => {
    return (
        <div className="flex flex-col items-center">
            {/* <Heading l={2} className="mb-2">Mary's experience</Heading> */}
            <Timeline fit={false}>

                <Paragraph variant="secondary">Mary has had an enduring understanding and <strong>love</strong> of cats that spans most of her life.</Paragraph>

                <Paragraph variant="secondary">She graduated with a bachelor's degree in Veterinary Science from Massey University in Palmerston North.</Paragraph>

                <Paragraph variant="secondary">Mary is a member of the Australian and New Zealand College of Veterinary Scientists. Her examination topic was feline medicine.</Paragraph>

                <Paragraph variant="secondary">Mary has a certificate in feline behaviour from the International society of Feline behaviour. She gained this qualification with distinction.</Paragraph>

                <Paragraph variant="secondary">Mary has worked as a primary care Veterinarian for 40 years, the last 20 years being in cat only practices.</Paragraph>

                <Paragraph variant="secondary">She now wishes to use her knowledge and experience to help cat owners understand and manage their cats behaviour problems.</Paragraph>

                <Paragraph variant="secondary">Mary has been blessed to be able to share her life with some amazing cats. Most of these cats were adopted as stray or feral cats.</Paragraph>

                <Paragraph variant="secondary">She currently shares her home with, and passionately loves, two cats. One is a twelve-year-old timid Ragdoll cross called Whisper, and the other is an extroverted three-year-old Burmese cross called Puff.</Paragraph>

            </Timeline>
        </div>
    )
}
