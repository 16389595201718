import React from 'react';
import img from "../../assets/HomePage/mary-with-cat.jpg";
import { Link } from 'react-router-dom';
import PawButton from '../Shared/PawButton';
import ImageAndContent from '../Shared/ImageAndContent/ImageAndContent';
import Heading from '../Shared/Heading';
import Paragraph from '../Shared/Paragraph';

export default function Intro() {

    return (
        <ImageAndContent imgSrc={img} imgAlt="Mary with a cat">

            <Heading l={2}>Online feline behavioural consultancy</Heading>

            <Paragraph variant="primary">
                Is your beloved cat exhibiting unusual or concerning behaviour? Often, these behaviours can be
                effectively managed once diagnosed! Mary Austin is a fellow cat lover with over twenty years'
                experience in feline-only vet care, and several world-class qualifications, including a
                certificate in Feline Behaviour from the International Society of Feline Medicine.
                She is excited to have the opportunity to walk with you in this journey to maximize the bond
                of love between you and your cat.
            </Paragraph>

            <div className="flex flex-row gap-6 flex-wrap justify-center">
                <PawButton variant="secondary" component={Link} to="/about" text="About Mary" />
                <PawButton variant="secondary" component={Link} to="/services" text="About our services" />
                <PawButton component={Link} to="/register" text="Contact us" />
            </div>

        </ImageAndContent>
    )
};