import useGet from "../../hooks/useGet"
import CircularImage from "./CircularImage";
import Paragraph from "./Paragraph";

export default function Testimonial({ images, text, from, clickable, onClick }) {

    const cursor = clickable ? 'cursor-pointer' : 'cursor-auto';
    const imageIndex = Math.floor(Math.random() * images.length);

    const handleClick = () => {
        if (clickable && onClick) onClick();
    }

    return (
        <div className="grid grid-rows-[auto_auto_auto] md:grid-rows-none grid-cols-[auto_1fr_auto] md:grid-cols-[auto_auto_1fr_auto] gap-4 mx-4 2xl:mx-0">

            <CircularImage src={`${process.env.REACT_APP_TESTIMONIAL_IMAGE_BASE_URL}/${images[imageIndex]}`}
                className={`border-blue-900 border-4 w-40 h-40 self-center justify-self-center col-span-3 md:col-span-1 ${cursor}`}
                onClick={handleClick} />

            <div className="self-start font-bold text-6xl">“</div>

            <div className="justify-self-center self-center italic text-center flex flex-col gap-4">
                {text.map((para, i) => <Paragraph key={i} variant="secondary" className="">{para}</Paragraph>)}

            </div>

            <div className="self-end font-bold text-6xl">„</div>

            <Paragraph variant="tertiary" className="italic text-right col-span-2 md:col-span-3">― {from}</Paragraph>
        </div>
    )
}

export function RandomTestimonial() {

    const { data, refresh } = useGet(`${process.env.REACT_APP_API_BASE_URL}/testimonials/random`);

    if (!data) return null;

    return <Testimonial clickable={true} onClick={() => refresh()} {...data} />
}