import React from 'react';

export default function Heading({ l, className, children, ...rest }) {
    const level = useLevel(l);

    const clazz = className ? `${level.className} ${className}` : level.className;

    return React.createElement(level.component, { className: clazz, ...rest }, ...children);
}

function useLevel(level) {
    switch (level) {

        case 2:
            return { className: 'text-2xl md:text-3xl lg:text-4xl', component: 'h2' }

        case 3:
            return { className: 'text-xl md:text-2xl lg:text-2xl font-light italic text-gray-500', component: 'h3' }

        case 1:
        default:
            return { className: 'text-3xl md:text-4xl lg:text-5xl', component: 'h1' }
    }
}