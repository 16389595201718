import picture from "../../assets/ServicesPage/services-cat.jpg";
import PawButton from "../Shared/PawButton";
import { Link } from "react-router-dom";
import ImageAndContent from "../Shared/ImageAndContent/ImageAndContent";
import Heading from "../Shared/Heading";
import Paragraph from "../Shared/Paragraph";

export default function ServicesIntro() {

    return (
        <ImageAndContent imgSrc={picture} imgAlt="Regal cat">

            <Heading l={2}>Our services</Heading>

            <Paragraph variant="primary">
                Mary offers a comprehensive set of online feline behavioural consultancy services, including Zoom and phone services,
                and exploration of your cat's past history to gain insights into causes of, and remedies to, any behavioural issues.
                Mary is also happy to refer you to a primary care veterinarian if required for any medication or blood tests.
            </Paragraph>

            <div className="flex flex-row gap-4 justify-center lg:justify-start">
                <PawButton variant="secondary" component={Link} to="/about" text="About Mary" />
                <PawButton component={Link} to="/register" text="Contact us" />
            </div>

        </ImageAndContent>
    )
}
