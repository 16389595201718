import React, { useState } from 'react';

export const AppContext = React.createContext({});

export function AppContextProvider({ children }) {

    const [title, setTitle] = useState(undefined);
    const [subtitle, setSubtitle] = useState(undefined);
    const [heroImage, setHeroImage] = useState(undefined);

    const setHeroBannerConfig = ({ title, subtitle, heroImage }) => {
        setTitle(title);
        setSubtitle(subtitle);
        setHeroImage(heroImage);
    }

    const context = {
        title,
        subtitle,
        heroImage,
        setHeroBannerConfig
    }

    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    )
}