import HeroBanner from './HeroBanner';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

export default function Page() {
    return (
        <div>
            <HeroBanner />

            <div className='max-w-7xl mx-auto pt-4 md:pt-2'>
                <Outlet />
            </div>

            <Footer />
        </div>
    )
}