import styles from './ImageAndContent.module.css';

export default function ImageAndContent({ imgSrc, imgAlt, imgRight, children, className }) {

    const imagePositionClass = imgRight ? styles.imageRight : styles.imageLeft;
    // const alignItems = imgRight ? "lg:items-end" : "lg:items-start";
    let clazz = `${styles.container} ${imagePositionClass} mx-4 2xl:mx-0`;
    if (className) clazz += ` ${className}`;
    return (
        <div className={clazz}>

            <img src={imgSrc} alt={imgAlt} className={`w-60 h-60 md:w-96 md:h-96 object-cover border-blue-900 border-4 place-self-center rounded-full`} />

            <div className={`${styles.content} flex flex-col justify-center items-center lg:items-start m-8 md:mx-16 xl:my-4 gap-4`}>
                {children}
            </div>
        </div>
    )
}