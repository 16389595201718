import image from '../../../assets/mary-smiling-cat.jpg';
import CircularImage from '../CircularImage';

export default function MaryLogo() {
    return (
        <div className="flex flex-row justify-start items-center gap-4">
            <CircularImage src={image} alt="footer logo" className="w-28 h-28 border-2 border-white" />
            <div className="flex flex-col gap-2">
                <h2 className="text-white text-3xl">Mary Austin</h2>
                <hr className="w-full border-white border-b-1" />
                <h3 className="text-gray-100 text-l font-light">NZ Cat Behaviour Detective</h3>
            </div>
        </div>
    );
}
