import MaryLogoFooter from "./MaryLogoFooter";
import { Link } from 'react-router-dom';

export default function CustomFooter() {
    return (
        <footer className="flex flex-col md:flex-row bg-blue-900 justify-around items-center p-4">
            {/* LHS here */}
            <MaryLogoFooter />

            {/* RHS here */}
            <div className="flex flex-col">
                <div className="flex justify-between mx-8 my-2">
                    <FooterLink to="/" text="Home" />
                    <FooterLink to="/services" text="Services" />
                    <FooterLink to="/about" text="About" />
                    <FooterLink to="/register" text="Register" />
                </div>

                <p className="text-gray-400 text-center">
                    Copyright ©️ 2022, Mary Austin. All rights reserved.
                </p>
            </div>
        </footer>
    );
}

function FooterLink({ to, text }) {
    return (
        <Link to={to} className="text-gray-100 hover:underline">{text}</Link>
    );
}