import React, { useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../Shared/Heading";
import Paragraph from "../Shared/Paragraph";
import PawButton from '../Shared/PawButton';
import Divider from "../Shared/Divider";
import axios from "axios";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const RegisterForm = () => {

    const [formData, setFormData] = useLocalStorage('formData', {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isError, setError] = useState(false);

    const getField = name => formData[name] ?? '';
    const setField = (e) => {
        const newData = { ...formData };
        newData[e.target.name] = e.target.value;
        setFormData(newData);
    }
    const clearFormFields = () => setFormData({});

    function handleSubmit(event) {
        event.preventDefault();

        setSubmitted(false);
        setSubmitting(true);
        setError(false);

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, formData)
            .then(() => {
                clearFormFields();
                setSubmitting(false);
                setSubmitted(true);
            })
            .catch(() => {
                setError(true);
                setSubmitting(false);
            });
    }

    if (isSubmitted) {
        return (
            <div className="px-2 mx-auto max-w-xl mt-4 flex flex-col items-center justify-center">
                <Heading l={3}>Thank you</Heading>
                <Paragraph variant="primary">
                    Your response has been recorded. Mary will be in touch soon!
                </Paragraph>
                <PawButton variant="primary" component={Link} to="/" text="Home" />
            </div>
        )
    }

    return (
        <>
            <div className="px-2 mx-auto max-w-xl mt-4">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <Label htmlFor="txtName">Name</Label>
                        <Input id="txtName" name="name" required autoComplete="name"
                            type="text" placeholder="Your full name" value={getField('name')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtEmail">Email</Label>
                        <Input id="txtEmail" name="email" required autoComplete="email"
                            type="email" placeholder="yourname@example.com" value={getField('email')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtNumber">Phone number</Label>
                        <Input id="txtNumber" name="phone" required autoComplete="tel"
                            type="tel" placeholder="021 123 4567" value={getField('phone')} onChange={setField} />
                    </div>

                    <SectionDivider />

                    <div className="mb-4">
                        <Label htmlFor="txtCatName">Your cat's name</Label>
                        <Input id="txtCatName" name="catName" required type="text" value={getField('catName')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtCatAge">Your cat's age <em>(estimate is fine if unknown)</em></Label>
                        <Input id="txtCatAge" name="catAge" type="number" required value={getField('catAge')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtCatBreed">Your cat's breed <em>(if known)</em></Label>
                        <Input id="txtCatBreed" name="catBreed" type="text" value={getField('catBreed')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtCatGender">Your cat's gender</Label>
                        <GenderSelect id="txtCatGender" name="catGender" required value={getField('catGender')} onChange={setField} />
                    </div>

                    <SectionDivider />

                    <div className="mb-4">
                        <Label htmlFor="txtBehaviourDescription">Brief description of behaviour</Label>
                        <TextArea id="txtBehaviourDescription" name="behaviourDescription" required
                            placeholder="Please provide a brief description of the behaviour you're concerned about. Let us know what's been tried before to remedy this behaviour, if anything."
                            value={getField('behaviourDescription')} onChange={setField} />
                    </div>
                    <div className="mb-4">
                        <Label htmlFor="txtExpectations">What are your expectations from us?</Label>
                        <TextArea id="txtExpectations" name="expectations"
                            placeholder="Please let us know your expectations around what Mary may be able to achieve with your cat."
                            value={getField('expectations')} onChange={setField} />
                    </div>

                    <div className="flex flex-col items-center gap-4 mb-4">
                        {/* <p className="text-gray-400 italic text-sm text-center">*Costs and terms of payment will be provided once interest has been registered</p> */}
                        <PawButton text={isSubmitting ? 'Submitting...' : 'Submit'} disabled={isSubmitting} component="button" type="submit" />
                    </div>
                </form>
            </div>
            {isError && <div className="px-2 mb-4 flex flex-col items-center justify-center">
                <Divider />
                <Heading l={3}>Something went wrong 😿</Heading>
                <Paragraph variant="tertiary" className="text-center max-w-3xl">
                    We're really sorry - something went wrong with your request. We haven't lost what you've typed -
                    it's still available above, and will still be available even if you leave the page and come back!
                    Please try and resubmit again later.
                </Paragraph>
            </div>}
        </>
    )
}

function Label({ children, ...rest }) {
    return (
        <label className="block text-gray-700 font-bold mb-2" {...rest}>{children}</label>
    )
}

function Input({ children, ...rest }) {
    return (
        <input className="shadow appearance-none border rounded w-full py-2 px-3 
            text-gray-700 leading-tight focus:outline-none focus:shadow-blue-400 focus:shadow-[0_0_0_3px]" {...rest}>{children}</input>
    )
}

function TextArea({ children, ...rest }) {
    return (
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 
            text-gray-700 leading-tight focus:outline-none focus:shadow-blue-400 focus:shadow-[0_0_0_3px]" rows={5} {...rest}>{children}</textarea>
    )
}

function Select({ children, ...rest }) {
    return (
        <select className="shadow appearance-none border rounded w-full py-2 px-3 
        text-gray-700 leading-tight focus:outline-none focus:shadow-blue-400 focus:shadow-[0_0_0_3px]" {...rest}>
            {children}
        </select>
    )
}

function GenderSelect({ ...rest }) {
    return (
        <Select {...rest}>
            <option value="male">Male</option>
            <option value="female">Female</option>
        </Select>
    )
}

const SectionDivider = () => <div className="mb-12" />;

// function defaultFormObject(...props) {
//     const obj = {};
//     props.forEach(p => obj[p] = '');
//     return obj;
// }

export default RegisterForm;